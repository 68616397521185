<template>
  <div class="introducePage">
    <div class="section-aside">
      <p>五谷画坊</p>
      <p>机构简介</p>
    </div>
    <div class="section-main">
      <div class="main-content">
        <p>
          五谷画坊创意助残中心筹建于2012年，并在2013年正式注册成立。机构成立的初衷是采取
          “公益、艺术与商业相结合”的模式，不断研发销售适合残障低保困难群体生产制作的具有地方风情和传统技艺相结合的文创产品，再自建营销渠道来带动他们的就业和创收机会。通过多年不断探索努力，先后创办运营了“宁波市公益产品展销中心”、“宁波慈善商店”、“鼓楼文创市集”等深受广大社群认可的实体项目。2018年底在南塘老街开办“灿益工坊·印象宁波文创店”，已运营4年多，已成为步行街上知名的文创产品打卡点。
        </p>
        <p>
          中心先后荣获了“宁波市助残先进单位”、“浙江省残联创业孵化基地”、“全国残联文创基地”、“2022年亚残运会展示点”等众多荣誉。并接待过前国家副主席李源潮、前省长袁家军、前市委书记刘琦、郑栅洁等领导人参观考察。
        </p>
        <p>
          2022年我们将更加务实求进，扬长避短，专注助残帮困扶贫初心，培养发掘困难群体特长和潜力，帮助他们的产品在线上线下拓展营销渠道，切实增加他们的收入和融入社会的自信心。
        </p>
      </div>

      <div class="main-video">
        <audio
          controls
          id="audios"
          height="50"
          autoplay="autoplay"
          src="./audio/introducePageFour_bg.mp3"
        ></audio>
      </div>

      <div class="main-picture">
        <img src="./img/introducePageFour_show_one.jpg" al t="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageFour_show_two.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageFour_show_three.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageFour_show_four.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageFour_show_five.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageFour_show_sex.jpg" alt="" />
      </div>
      <div class="main-picture">
        <img src="./img/introducePageFour_show_seven.jpg" alt="" />
      </div>
      <div class="main-picture">
        <img src="./img/introducePageFour_show_eight.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const that = this;
      //--创建页面监听，等待微信端页面加载完毕 触发音频播放
      document.addEventListener("WeixinJSBridgeReady", function () {
        that.$nextTick(() => {
          document.getElementById("audios").play();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.introducePage {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 378px 0 80px;
  background: url(./img/introducePage_bg.png) no-repeat top left;
  background-size: 100% auto;
  background-color: #dfeeff;
  .section-aside {
    position: absolute;
    top: 160px;
    left: 36px;
    font-size: 72px;
    color: #ffffff;
    line-height: 84px;
    text-shadow: 5px 2px #4a7196;
    p:last-child {
      padding-left: 40px;
    }
  }
  .section-main {
    box-sizing: border-box;
    padding: 38px 30px 0px;
    width: 100%;
    background: url(./img/introducePage_across.png) no-repeat top center;
    background-size: calc(100% - 20px) 80px;
    .main-content {
      // width: 100%;
      padding: 50px 30px 56px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      font-size: 28px;
      line-height: 40px;
      color: rgba(0, 0, 0, 0.85);
      // text-align-last: justify;
      text-align: justify;
      text-indent: 2em;
    }
    .main-video {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 40px 22px 40px;
      text-align: center;
      height: 100px;
      ::v-deep video {
        width: 100%;
        height: 100%;
        object-fit: fill;
        background: #fff;
      }
    }
    .main-picture {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 68px 22px 94px;
      img {
        width: 100%;
        // width: 646px;
        // height: 486px;
      }
      &:not(:last-child)::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -68px;
        left: 28px;
        width: 634px;
        height: 106px;
        background: url(./img/introducePage_vertical.png) no-repeat top center;
        background-size: 100% 100%;
        z-index: 1;
      }
    }
  }
}
</style>
